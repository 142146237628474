<template>
  <div
    class="flex mb-8"
    :class="!isCommentRightAligned ? 'flex-row-reverse' : ''"
  >
    <div class="flex flex-col w-12 flex-grow-0 justify-start">
      <base-svg
        class="h-8 w-8 max-h-8 mt-2 mx-auto"
        :class="!isCommentRightAligned ? 'text-custom-purple-1' : 'text-primary-red'"
        src="icons/userCircle.svg"
        :svg-attributes="{ class: 'h-full w-full' }"
        tag="span"
      />
      <div
        v-if="commentDeleteState !== INVISIBLE && comment.isDeletable"
        class="flex flex-col w-12 mx-auto"
      >
        <!-- v-if="!showDelConfirm" -->
        <!-- @click="showDelConfirm=true" -->
        <base-svg
          class="h-6 w-6 max-h-6 mt-8 text-primary-red mx-auto cursor-pointer"
          src="icons/cross.svg"
          :svg-attributes="{ class: 'h-full w-full' }"
          tag="span"
          @click="setCommentDeleteModalVisibility(true, comment.noteKey)"
        />
        <!-- <div
          v-else
          class="w-full mt-6 flex flex-col text-xs font-semibold"
        >
          <div
            class="my-1 mx-auto text-primary-red cursor-pointer"
            @click="deleteComment(comment.noteKey)"
          >
            YES
          </div>
          <div
            class="my-2 mx-auto cursor-pointer"
            @click="showDelConfirm=false"
          >
            NO
          </div>
        </div> -->
      </div>
    </div>
    <div>
      <div
        class="flex justify-between items-center"
        :class="!isCommentRightAligned ? 'flex-row-reverse' : ''"
      >
        <div
          class="flex"
          :class="!isCommentRightAligned ? 'flex-row-reverse ml-10' : 'mr-10'"
        >
          <div
            class="flex flex-col mx-2 mb-2"
            :class="!isCommentRightAligned ? 'text-right' : 'text-left'"
          >
            <div class="text-custom-gray-7 font-semibold text-base">
              {{ comment.submissionStepKeyName != 'Licensee' && comment.refObjTypeKey == 2 ? comment.creatUserId : comment.creatUserName }}
            </div>
            <div class="text-custom-blue-1 font-normal text-sm">
              {{ comment.submissionStatusKeyName }}
            </div>
          </div>
        </div>
        <div class="text-xs font-normal text-custom-gray-1 pt-4">
          {{ formatDate(comment.creatTmstmp, 'MM-DD-YYYY ( HH:MM )', true) }}
        </div>
      </div>
      <div
        class="p-4"
        :class="[
          `border-2 border-${defaultCardColor}`,
          !isCommentRightAligned ? 'rounded-l-2xl rounded-br-2xl' : 'rounded-r-2xl rounded-bl-2xl'
        ]"
      >
        <div class="text--gray-1 font-semibold text-sm mb-2 overflow-auto">
          <pre style="white-space: pre-wrap; word-wrap: break-word;">{{ comment.description }}</pre>
        </div>
        <div class="text-custom-gray-1 text-right uppercase text-10 font-semibold tracking-widest">
          <span :class="`p-2 rounded-lg text-center text-black bg-${defaultCardColor}`">
            {{ comment.submissionStageKeyName }}
          </span>
          <span class="px-2 font-light text-base">/</span>
          <span :class="`p-2 rounded-lg text-center text-black bg-${defaultCardColor}`">
            {{ comment.reviewName ? comment.reviewName : comment.submissionStepKeyName }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <base-modal
    v-model="showDeleteCommentConfirmationModal"
    modal-title="Confirm Message"
    @hidden="hideDeleteCommentConfirmationModal"
  >
    <template #modalBody>
      <div class="px-4 py-3">
        <div class="font-sm text-center mb-2">
          Are you sure, you want to delete this comment ?
        </div>
        <div class="text-center mt-2">
          <base-button
            class="mr-3"
            variant="btn-primary"
            text="Yes"
            @click="confirmCommentDeleteModal()"
          />
          <base-button
            variant="btn-link"
            text="No"
            @click="closeCommentDeleteModal()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, ref, onMounted } from 'vue';
import { formatDate } from '@/helpers/util.js';
import useUserAuth from '@/hooks/userAuth.js';

import { useStore } from 'vuex';
import useComments from '@/components/brand-assurance-comments/comments.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS } from '@/constants/alerts';
import { LEGAL_REVIEW_STEP, EDITABLE, INVISIBLE, LICENSEE_STEP, CONTRACT_COORDINATOR } from '@/constants/submissions';
import useSubmissionForm from '@/components/brand-assurance-submission-form/submissionForm.js';
import { LICENSEE_ROLE } from '@/constants//ba-roles.js';

export default {
    name: 'CommentCard',

    components: {
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        comment: {
            type: Object,
            required: true,
            description: 'Object containing data of comment'
        },
        defaultCardColor: {
            type: String,
            default: '',
            description: 'Default card color applied in case no color is provided in comment object'
        },
        modalTitle: {
            type: String,
            default: '',
            description: 'Modal Title to differentiate between licensee and internal comments'
        }
    },

    setup (props) {
        const { submissionForm } = useSubmissionForm();
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { setCommentsLists } = useComments();

        const showDelConfirm = ref(false);

        onMounted(() => {
            const scrollTarget = document.querySelector('#commentScrollToLast');
            if (scrollTarget) {
                scrollTarget.scrollIntoView({ block: 'center' });
            }
        });

        const isCommentRightAligned = computed(() => {
            return props.comment.submissionStepKeyName !== 'Licensee' && !props.comment.alignLeft;
        });

        const { userRoles } = useUserAuth();
        const commentDeleteState = computed(() => {
            if (props.modalTitle.toLowerCase().includes('internal') || submissionForm.submissionStatus === 'Completed' || submissionForm.currentStepName === LICENSEE_STEP) {
                return INVISIBLE;
            } else if (userRoles.value.length === 1 && userRoles.value[0] === LICENSEE_ROLE) {
                return INVISIBLE;
            } else {
                return EDITABLE;
            }
        });

        const deleteComment = async (noteKey) => {
            try {
                await store.dispatch('baSubmissions/deleteComment', {
                    params: { noteKey }
                });

                const currentList = store.getters['baSubmissions/getSubmissionComments'];
                const __comments = currentList.sort((a, b) => a.noteKey - b.noteKey); // Ascending Sort
                setCommentsLists(__comments.filter(c => c.noteKey !== noteKey));

                notificationsStack.value.push({
                    type: SUCCESS,
                    message: 'Comment has been deleted'
                });
            } catch (e) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: 'Error while removing the comment'
                });
            } finally {
                showDelConfirm.value = false;
            }
        };

        const showDeleteCommentConfirmationModal = ref(false);
        const selectedCommentNote = ref('');
        const setCommentDeleteModalVisibility = (visibility, selectedComment) => {
            if (visibility) selectedCommentNote.value = selectedComment;
            showDeleteCommentConfirmationModal.value = visibility;
        };
        const closeCommentDeleteModal = () => {
            setCommentDeleteModalVisibility(false, '');
        };

        const hideDeleteCommentConfirmationModal = () => {
            setCommentDeleteModalVisibility(false, '');
        };
        const confirmCommentDeleteModal = () => {
            deleteComment(selectedCommentNote.value);
            setCommentDeleteModalVisibility(false, '');
        };

        return {
            isCommentRightAligned,
            formatDate,
            deleteComment,
            showDelConfirm,
            commentDeleteState,
            INVISIBLE,
            // delete modal
            confirmCommentDeleteModal,
            hideDeleteCommentConfirmationModal,
            closeCommentDeleteModal,
            showDeleteCommentConfirmationModal,
            setCommentDeleteModalVisibility
        };
    }
};
</script>
